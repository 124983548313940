<template>
  <v-card height="100vh" class="primarygrad mb-n15">
    <v-row align="center" no-gutters style="height: 100%">
      <v-flex xs12 sm10 offset-sm1 md="8" offset-md="2">
        <v-card elevation="7" class="secondarygrad rounded">

          <v-row style="margin: 0px">
            <v-flex
              xs12
              sm5
              md5
            >
              <v-card
                color="transparent"
                flat
                style="padding: 10px; text-align: center !important"
              >
                <v-card-text style="margin-bottom: 25px">
                  <img
                    :src="logo"
                    alt="Godial Logo"
                    style="
                      width: 80px;
                      height: auto;
                      border-radius: 50px;
                      margin-bottom: 10px;
                    "
                  />
                  <h2 style="margin-bottom: 30px">Godial Enterprise</h2>
                  <h3 style="margin: 5px 0px">Automatic Call Dialer and CRM</h3>
                  <div style="padding: 5px 15px; text-align: center">
                    <h5 style="
                        line-height: 17px;
                        font-weight: 500;
                        font-size: 12px;
                      ">
                      Setup your call center in minutes. Simply create your
                      account, add team, download team app and start calling.
                    </h5>
                  </div>
                </v-card-text>
                <v-footer
                  color="transparent"
                  absolute
                >
                  <v-flex style="text-align: center !important">
                    <p style="font-size: 12px; opacity: 0.6; margin-bottom: 0px">
                      © 2020 Godial Enterprise. All rights reserved
                    </p>
                  </v-flex>
                </v-footer>
              </v-card>
            </v-flex>
            <v-flex
              xs12
              sm7
              md7
              style="padding: 0px; margin: 0px"
            >
              <v-card
                v-if="loginDisplay"
                color="transparent"
                elevation="0"
                style="padding: 15px 50px; text-align: center"
                @keyup.enter="doLogin"
              >
                <v-card-title
                  primary-title
                  class="justify-center"
                >Welcome to GoDial</v-card-title>
                <v-card-subtitle>
                  Login to your GoDial account with your username and password
                </v-card-subtitle>

                <v-card-text>
                  <v-flex
                    xs12
                    sm10
                    offset-sm1
                    md10
                    offset-md1
                  >
                    <v-text-field
                      v-model="login.username"
                      dense
                      label="User Name / Email"
                      outlined
                      prepend-inner-icon="account_circle"
                    ></v-text-field>
                  </v-flex>
                  <v-flex
                    xs12
                    sm10
                    offset-sm1
                    md10
                    offset-md1
                  >
                    <v-text-field
                      v-model="login.password"
                      dense
                      label="Password"
                      outlined
                      :append-icon="visible ? 'visibility' : 'visibility_off'"
                      :type="visible ? 'text' : 'password'"
                      prepend-inner-icon="lock"
                      @click:append="() => (visible = !visible)"
                    ></v-text-field>
                  </v-flex>

                  <v-flex
                    xs12
                    sm10
                    offset-sm1
                    md10
                    offset-md1
                    style="text-align: center !important; margin-bottom: 10px"
                  >
                    <v-btn
                      text
                      color="secondary lighten-3"
                      style="font-size: 12px; text-transform: none"
                      small
                      @click="forgotPasswordDisplay = true"
                    >Forgot Password</v-btn>
                  </v-flex>
                  <v-flex
                    xs12
                    sm10
                    offset-sm1
                    md10
                    offset-md1
                    style="margin-bottom: 10px"
                  >
                    <v-btn
                      color="black white--text"
                      block
                      @click="doLogin"
                    >Login</v-btn>
                  </v-flex>
                  <v-flex
                    xs12
                    sm10
                    offset-sm1
                    md10
                    offset-md1
                  >
                    <v-btn
                      color="black white--text"
                      block
                      @click="signupDialogFun()"
                    >Create Account</v-btn>
                  </v-flex>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-row>
        </v-card>
      </v-flex>
    </v-row>

    <!-- forgot password dialog -->
    <v-dialog
      v-model="forgotPasswordDisplay"
      width="400"
      persistent
    >
      <v-card class="mx-auto">
        <v-card-title class="primarygrad">
          <h4 style="
              font-size: 18px;
              color: #fafafa;
              letter-spacing: 0.5px;
              font-weight: 500;
            ">
            Forgot Password
          </h4>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="padding: 20px">
          <v-text-field
            v-model="forgotItem.email"
            dense
            label="Email"
            prepend-inner-icon="email"
            outlined
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            text
            @click="forgotPasswordDisplay = false"
          >Close</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primarygrad white--text"
            @click="forgotPassword"
          >Reset</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- loader -->
    <v-dialog
      v-model="dialog"
      persistent
      width="300"
    >
      <v-card
        color="primarygrad white--text"
        dark
      >
        <v-card-text>
          Please wait
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- signup page -->
    <v-dialog
      v-model="signupDialog"
      max-width="600"
      persistent
    >
      <v-card elevation="0">
        <v-card-title class="primary">
          <h3 style="color: #fafafa; font-size: 20px; font-weight: 500">
            Sign Up
          </h3>
          <v-spacer></v-spacer>
          <v-btn
            fab
            small
            text
            class="white--text"
            @click="signupDialog = false"
          >
            <v-icon :size="22">close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text style="padding: 0px; margin: 0px">
          <v-stepper
            v-model="step"
            style="box-shadow: none"
          >
            <v-stepper-header>
              <v-stepper-step
                :complete="step > 1"
                step="1"
              >Organization</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="2">Account</v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <v-card color="transparent">
                  <v-card-text>
                    <v-flex
                      xs12
                      sm10
                      offset-sm1
                      md10
                      offset-md1
                    >
                      <v-text-field
                        v-model="signup.nameOfCompany"
                        dense
                        prepend-inner-icon="business"
                        label="Organization Name*"
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex
                      xs12
                      sm10
                      offset-sm1
                      md10
                      offset-md1
                    >
                      <v-select
                        v-model="signup.size.value"
                        dense
                        prepend-inner-icon="supervised_user_circle"
                        label="Organization Size"
                        :items="[
                          { text: 'Small (<10 Members)', value: 10 },
                          { text: 'Medium (<100 Members)', value: 100 },
                          { text: 'Large (100+ Members)', value: 500 },
                        ]"
                        outlined
                      ></v-select>
                    </v-flex>

                    <v-flex
                      xs12
                      sm10
                      offset-sm1
                      md10
                      offset-md1
                    >
                      <v-autocomplete
                        v-model="signup.country"
                        :items="getCountriesList()"
                        label="Country"
                        prepend-inner-icon="location_on"
                        item-text="name"
                        item-value="name"
                        outlined
                        dense
                        required
                      ></v-autocomplete>
                    </v-flex>
                  </v-card-text>
                </v-card>
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-card color="transparent">
                  <v-card-text>
                    <v-flex
                      xs12
                      sm10
                      offset-sm1
                      md10
                      offset-md1
                    >
                      <v-text-field
                        v-model="signup.nameOfUser"
                        dense
                        prepend-inner-icon="perm_contact_calendar"
                        label="Name *"
                        outlined
                      ></v-text-field>
                    </v-flex>

                    <v-flex
                      xs12
                      sm10
                      offset-sm1
                      md10
                      offset-md1
                      style="margin-bottom: 25px"
                    >
                      <vue-tel-input
                        v-model="signup.phone"
                        :valid-characters-only="true"
                        class="secondary--text"
                        @input="getPhoneNumber"
                      ></vue-tel-input>
                    </v-flex>

                    <v-flex
                      xs12
                      sm10
                      offset-sm1
                      md10
                      offset-md1
                    >
                      <v-text-field
                        v-model="signup.email"
                        dense
                        :rules="rulesEmail"
                        prepend-inner-icon="email"
                        label="Email"
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex
                      xs12
                      sm10
                      offset-sm1
                      md10
                      offset-md1
                    >
                      <v-text-field
                        :value="signup.userid.toLowerCase()"
                        dense
                        :rules="rulesUsername"
                        prepend-inner-icon="account_circle"
                        label="User Name *"
                        outlined
                        @input="signup.userid = $event.toLowerCase()"
                      ></v-text-field>
                    </v-flex>

                    <v-flex
                      xs12
                      sm10
                      offset-sm1
                      md10
                      offset-md1
                    >
                      <v-text-field
                        v-model="signup.password"
                        dense
                        prepend-inner-icon="lock"
                        label="Password *"
                        :append-icon="visible ? 'visibility' : 'visibility_off'"
                        :type="visible ? 'text' : 'password'"
                        outlined
                        :rules="passwordRules"
                        @click:append="() => (visible = !visible)"
                      ></v-text-field>
                    </v-flex>

                    <v-flex
                      xs12
                      sm10
                      offset-sm1
                      md10
                      offset-md1
                    >
                      <v-text-field
                        v-model="signup.confirmPwd"
                        dense
                        prepend-inner-icon="lock"
                        label="Confirm Password *"
                        :append-icon="
                          visibleConfirm ? 'visibility' : 'visibility_off'
                        "
                        :type="visibleConfirm ? 'text' : 'password'"
                        outlined
                        :rules="confirmPwdRules"
                        @click:append="() => (visibleConfirm = !visibleConfirm)"
                      ></v-text-field>
                    </v-flex>

                    <v-flex
                      xs12
                      sm10
                      offset-sm1
                      md10
                      offset-md1
                    >
                      <v-checkbox
                        v-model="termsCheckbox"
                        color="green"
                      >
                        <template v-slot:label>
                          <div>
                            I have read and agree to the
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <a
                                  target="_blank"
                                  href="https://godial.cc/terms.html"
                                  @click.stop
                                  v-on="on"
                                >
                                  Terms and Conditions
                                </a>
                              </template>
                              Terms and Conditions
                            </v-tooltip>
                            and
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <a
                                  target="_blank"
                                  href="https://godial.cc/privacy.html"
                                  @click.stop
                                  v-on="on"
                                >
                                  Privacy Policy
                                </a>
                              </template>
                              Privacy Policy
                            </v-tooltip>
                          </div>
                        </template>
                      </v-checkbox>
                    </v-flex>
                  </v-card-text>
                </v-card>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            v-if="step != 1"
            text
            @click="step--"
          >Back</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="step != 2"
            color="primary"
            @click="next"
          >Next</v-btn>
          <v-btn
            v-else
            color="primary"
            :disabled="dialog ? true : false"
            :loading="dialog ? true : false"
            @click="doSignup"
          >Sign Up</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import countries from "../js/countries";
import fb from "../js/firebase";

var Base64 = require("js-base64").Base64;

export default {
  data() {
    return {
      // confirmPwd: "",
      passwordRules: [
        v => !!v || "This field is required.",
        v =>
          /^(?!.* )(?=.*[a-zA-Z]).(?=.*[0-9]).{7,}$/.test(v) ||
          "Minimum eight characters, at least one letter and one number."
      ],
      rulesEmail: [
        v => !!v || "This field is required.",
        v =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w+)+$/.test(v) ||
          "E-mail must be valid"
      ],

      rulesUsername: [
        v => !!v || "This field is required.",
        v =>
          /^\w+(\.\w+)?$/.test(v) ||
          "Userid contains only letters,numbers and dot"
      ],
      agree: false,
      forgotPasswordDisplay: false,
      forgotItem: {
        email: ""
      },
      signupDialog: false,
      step: 1,
      loginDisplay: true,
      signup: {
        nameOfCompany: "",
        nameOfUser: "",
        size: { text: "Small (<10 Members)", value: 10 },
        email: "",
        password: "",
        confirmPwd: "",
        userid: "",
        phone: "",
        country: "India"
      },
      dialog: false,
      login: {
        username: "",
        password: ""
      },
      visible: false,
      visibleConfirm: false,
      logo: require("../assets/logo.png"),
      termsCheckbox: false,
      clientRegisterIP: null
    };
  },
  computed: {
    ...mapGetters(["ENDPOINT"]),
    confirmPwdRules(v) {
      if (this.signup.confirmPwd == "") {
        return ["This field is required."];
      } else if (this.signup.password != this.signup.confirmPwd) {
        return ["Password did'nt match."];
      }
      return [];
    }
  },
  watch: {},
  created() {
    var query = this.$route.query;

    if (Object.keys(this.$route.query).length > 0) {
      if (this.$route.query.skipLogin) {
        this.doLogin(true);
      } else if (this.$route.query.l && this.$route.query.d) {
        try {
          this.login.username = Base64.decode(this.$route.query.l);
          this.login.password = Base64.decode(this.$route.query.d);

          this.doLogin();
        } catch (e) {
          console.log(e);
        }
      }
    }

    if (typeof this.$store.state.user.token != "undefined") {
      this.$router.push("/home");
    }
    // this.getClientIp();
    this.refresh();
  },
  methods: {
    signupDialogFun() {
      if (process.env.NODE_ENV === "production")
        window.location.href = "https://godial.cc/?id=signupFromWeb";
      else this.signupDialog = true;
    },
    // getClientIp() {
    //   const self = this;
    //   self.$http
    //     .get(`https://api.ipregistry.co/?key=nrmmydm8ugq367hq`)
    //     .then((resp) => {
    //       var data = resp.body;
    //       self.clientRegisterIP = data.ip;
    //     })
    //     .catch((err) => {
    //       console.log("Error", err);
    //     });
    // },
    getCountriesList() {
      this.countries = countries;
      return this.countries;
    },

    getPhoneNumber(e, phone) {
      if (e) {
        return (this.signup.phone = phone.number.e164);
      } else {
        return (this.signup.phone = "");
      }
    },

    forgotPassword() {
      this.loader = true;
      if (this.forgotItem.email == "") {
        this.loader = false;
        return this.$swal({
          type: "warning",
          text: "Email Id is not provided"
        });
      }

      if (
        !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w+)+$/.test(this.forgotItem.email)
      ) {
        this.loader = false;
        return this.$swal({ type: "warning", text: "Email Id invalid" });
      }

      this.$http
        .post(`${this.ENDPOINT}/accounts/reset-password-request`, {
          email: this.forgotItem.email
        })
        .then(response => {
          this.$swal({
            type: "success",
            text: "Please check your mail to reset your password."
          });
          this.forgotPasswordDisplay = false;
          this.loader = false;
        })
        .catch(e => {
          this.loader = false;
          this.forgotPasswordDisplay = true;
          this.$swal({ type: "error", text: e.body.error.message });
        });
    },

    async doLogin() {
      const self = this;
      self.dialog = true;

      var loginData = false;
      var skipLogin = false;
      var redirect = false;
      var redirectTo = "";
      if (typeof self.$route.query.skipLogin != "undefined")
        skipLogin = self.$route.query.skipLogin;

      if (!skipLogin) {
        if (this.login.username == "" || this.login.password == "") {
          self.dialog = false;
          return self.$swal({
            type: "warning",
            text: "Username/Password is blank"
          });
        }

        try {
          loginData = await this.$http.post(this.ENDPOINT + "/accounts/login", {
            username: this.login.username,
            password: this.login.password
          });
        } catch (err) {
          self.dialog = false;
          console.log(err);
          if (err.status == 0) {
            self.$swal({ type: "error", text: "Connection Error" });
          } else if (err.status == 401 && err.statusText == "Unauthorized") {
            self.$swal({ type: "error", text: "Invalid Username or Password" });
          } else {
            self.$swal({ type: "error", text: err.body.error.message });
          }
        }
      } else {
        window.localStorage.clear();
      }

      console.log("logindata", loginData);

      var accessToken = "";
      if (!skipLogin) accessToken = loginData.body.id;
      else accessToken = self.$route.query.ac10n;

      self.$http.headers.common.authorization = accessToken;

      var userId = "";

      if (!skipLogin) {
        userId = loginData.body.userId;
      } else {
        userId = self.$route.query.userId;
        console.log("Skipping login", accessToken);
      }

      console.log(
        "Redirect  ,",
        typeof self.$route.query.redirect,
        self.$route.query.redirect
      );

      if (self.$route.query.redirect === "true") {
        redirect = true;
        redirectTo = self.$route.query.to;
      }

      self.$http
        .get(this.ENDPOINT + "/accounts/" + userId + "?filter[include]=teams", {
          headers: {
            authorization: accessToken
          }
        })
        .then(user => {
          console.log("user", user.body);
          if (user.body.role == "Agent") {
            alert("Only admins can access the dashboard");
            window.localStorage.clear();
            window.location.reload();
            return;
          }
          user = user.body;
          var token = accessToken;
          user.token = token;

          self.$store
            .dispatch("setUser", user)
            .then(() => {
              console.log("user", self.$store.state.user);
              try {
                fb.init(self.$store.state);
              } catch (e) {
                console.log(e);
              }

              self.dialog = false;
              self.$store.commit("setSkipLogin", skipLogin ? true : false);
              console.log("redirect2", redirect, redirectTo);
              if (redirect) {
                console.log("redirecting");
                self.$router.push(redirectTo);
              } else {
                console.log("not redirect");
                self.refresh();
              }
            })
            .catch(e => {
              self.dialog = false;
              self.refresh();
            });
        });
      //Fetch the user
    },
    refresh() {
      //check if logged in and goto home
      if (this.$store.getters.user) {
        this.$router.replace({ path: "/home" });
      }
    },

    next() {
      if (this.step == 1 && this.signup.nameOfCompany == "") {
        this.step = 1;
        return;
      }

      return this.step++;
    },

    doSignup() {
      const self = this;
      self.dialog = true;

      // fields should not be blank
      if (
        self.signup.nameOfCompany == "" ||
        self.signup.nameOfUser == "" ||
        self.signup.userid == "" ||
        self.signup.password == "" ||
        self.signup.country == ""
      ) {
        self.dialog = false;
        return self.$swal({
          type: "warning",
          text: "Required fields are blank"
        });
      }
      // password should be 6 characters
      if (self.signup.password.length < 8) {
        self.dialog = false;
        return self.$swal({
          type: "warning",
          text: "Password should be 8 characters"
        });
      }

      if (self.signup.email == "") {
        self.dialog = false;
        return self.$swal({
          type: "warning",
          text: "Email Id is Required Field"
        });
      } else {
        if (
          !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w+)+$/.test(self.signup.email)
        ) {
          self.dialog = false;
          return self.$swal({ type: "warning", text: "Email Id invalid" });
        }
      }

      if (self.signup.userid != "") {
        if (!/^\w+(\.\w+)?$/.test(self.signup.userid)) {
          self.dialog = false;
          return self.$swal({ type: "warning", text: "User Id is invalid" });
        } else {
          var username = self.signup.userid.toLowerCase();
        }
      }

      if (
        !/^(?!.* )(?=.*[a-zA-Z]).(?=.*[0-9]).{7,}$/.test(self.signup.password)
      ) {
        self.dialog = false;
        return self.$swal({
          type: "warning",
          text:
            "Password should have minimum eight characters, at least one letter and one number."
        });
      }

      if (
        !/^(?!.* )(?=.*[a-zA-Z]).(?=.*[0-9]).{7,}$/.test(self.signup.confirmPwd)
      ) {
        self.dialog = false;
        return self.$swal({
          type: "warning",
          text:
            "Confirm password should have minimum eight characters, at least one letter and one number."
        });
      }

      if (self.signup.password != self.signup.confirmPwd) {
        self.dialog = false;
        return self.$swal({
          type: "warning",
          text: "Passwords did'nt match"
        });
      }

      if (!self.termsCheckbox) {
        self.dialog = false;
        return self.$swal({
          type: "warning",
          text: "Please Select Privacy Policy & Terms Checkbox"
        });
      }

      if (!self.clientRegisterIP) {
        self.clientRegisterIP = "";
      }

      self.$http
        .post(self.ENDPOINT + "/companies/signup", {
          nameOfCompany: self.signup.nameOfCompany,
          nameOfUser: self.signup.nameOfUser,
          email: self.signup.email,
          username: username,
          role: "Manager",
          phone: self.signup.phone,
          password: self.signup.password,
          country: self.signup.country,
          size: self.signup.size.value,
          signupIP: self.clientRegisterIP
        })
        .then(res => {
          self.$store.dispatch("setUser", res.body);
          self.$http.headers.common["Authorization"] =
            "Basic " + res.body.token;
          self.dialog = false;
          self.refresh();
        })
        .catch(err => {
          self.dialog = false;
          if (err.status == 0) {
            return self.$swal({ type: "error", text: "Could not connect" });
          } else if (err.status == 422) {
            if ("username" in err.body.error.details.messages) {
              return self.$swal({
                type: "error",
                text: [...err.body.error.details.messages.username]
              });
            }

            if ("email" in err.body.error.details.messages) {
              return self.$swal({
                type: "error",
                text: [...err.body.error.details.messages.email]
              });
            }
          }

          return self.$swal({ type: "error", text: "Some error occured" });
        });
    }
  }
};
</script>

<style scoped>
/* .container {
  max-width: 100%;
  height: 100%;
}
.v-divider {
  margin-bottom: 5px;
}

.bgLogin {
  background: rgb(90, 255, 118);
  background: radial-gradient(
    circle,
    rgba(90, 255, 118, 1) 0%,
    rgba(76, 217, 100, 1) 100%
  );
} */
</style>
